import Cookie from 'js-cookie';
import PropTypes from 'prop-types';
import React, { useContext, useRef, useState } from 'react';
import Recaptcha from 'react-google-recaptcha';
import { Link, Prompt, useLocation, useRouteMatch } from 'react-router-dom';

import {
  APIBaseUrlContext,
  BaseUrlContext,
  ConfigContext,
  GlossaryContext,
  ProjectContext,
  VariablesContext,
  VersionContext,
} from '@core/context';
import useClassy from '@core/hooks/useClassy';
import useRoute from '@core/hooks/useRoute';

import Button from '@ui/Button';
import ButtonGroup from '@ui/ButtonGroup';
import DateLine from '@ui/DateLine';
import Dropdown from '@ui/Dropdown';
import Icon from '@ui/Icon';
import MarkdownEditor from '@ui/MarkdownEditor';
import { serialize } from '@ui/MarkdownEditor/editor';
import Notification, { notify } from '@ui/Notification';
import Spinner from '@ui/Spinner';
import Tooltip, { TooltipSingleton } from '@ui/Tooltip';

import SuggestionComments from './components/Comments';
import SuggestionStatus from './components/Status';
import classes from './style.module.scss';

const SuggestionDiff = ({ ...props }) => {
  const bem = useClassy(classes, 'SuggestionDiff');

  const query = new URLSearchParams(useLocation().search);

  const apiBaseUrl = useContext(APIBaseUrlContext);
  const baseUrl = useContext(BaseUrlContext);
  const { url } = useRouteMatch();
  const { domainFull } = useContext(ConfigContext);
  // This is using the legacy API so this is intentionally
  // using version_clean instead of version
  const { version_clean: version } = useContext(VersionContext);

  const {
    state: { update, diffHtml, pageSlug, effectiveUserName },
    loading,
    mutate,
  } = useRoute(props);

  const terms = useContext(GlossaryContext);
  const vars = useContext(VariablesContext);

  const $recaptcha = useRef();
  const { reCaptchaSiteKey } = useContext(ProjectContext).project;

  const [changesView, setChangesView] = useState('diff');
  const [editView, setEditView] = useState(!!query.get('editing'));
  const [pristine, setPristine] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [editorValue, setEditorValue] = useState();

  const errorNotification = (
    <Notification dismissible kind="error" position="bottom-center">
      Couldn’t update suggestion.
    </Notification>
  );

  const saveUpdates = async () => {
    if (isSaving) return;
    setIsSaving(true);
    const body = serialize({ children: editorValue });

    const base = apiBaseUrl === '/' ? '' : apiBaseUrl;
    const uri = `${base}/api/v${version}/suggested-edits/${update._id}`;

    const xhr = await fetch(uri, {
      headers: {
        'Content-Type': 'application/json',
        'X-XSRF-TOKEN': Cookie.get('XSRF-TOKEN') ?? '',
      },
      body: JSON.stringify({ body }),
      method: 'PUT',
      mode: 'cors',
      credentials: 'include',
    });
    if (!xhr.ok) notify(errorNotification);
    else {
      try {
        const res = await xhr.json();
        mutate({ update, pageSlug, ...res }, { revalidate: true, populateCache: false });
        setPristine(true);
      } catch (err) {
        notify(errorNotification);
      }
    }
    setIsSaving(false);
  };

  const commentsDropdown = (
    <Dropdown align="right" justify="start" lazyMountContent sticky>
      {/**
       * Wrapping the Tooltip in a div with role="combobox" as a workaround
       * for Tooltip ref forwarding issue within a Dropdown
       * @todo: RM-8157 fix ref forwarding in the Tooltip within a Dropdown
       */}
      <div aria-controls={update?._id} aria-expanded={false} role="combobox">
        <Tooltip content="Comments" singleton={bem('-navTooltip')}>
          <Button kind="secondary" outline size="sm">
            <Icon name="message-circle" />
          </Button>
        </Tooltip>
      </div>
      <SuggestionComments id={update?._id} recaptcha={$recaptcha} />
    </Dropdown>
  );

  if (loading)
    return (
      <div className={bem('&', 'rm-Suggestion', '_loading')}>
        <Spinner size={300} />
      </div>
    );
  return (
    <>
      <div className={bem('&', 'rm-SuggestionDiff')}>
        <header className={bem('-header')}>
          <h1 className={bem('-title')}>
            <Tooltip content="View all suggestions…" placement="bottom">
              <Link to="/suggested-edits">
                <Icon name="suggested-edits" />
              </Link>
            </Tooltip>
            {update?.name}
            <SuggestionStatus {...update} />
          </h1>
          <p>{update?.comment}</p>
        </header>

        <TooltipSingleton delay={[300, 50]} id={bem('-navTooltip')} moveTransition="transform 200ms ease" />
        <nav className={bem('-nav', 'Flex Flex-align=center')}>
          {!(update?.merged || update?.closed) ? (
            <ButtonGroup highlightSelection={false}>
              <Tooltip content={!editView ? 'Edit' : pristine ? 'Cancel' : 'Discard…'} singleton={bem('-navTooltip')}>
                <Button
                  kind={editView ? 'destructive' : 'secondary'}
                  onClick={() => {
                    if (editView && !pristine) {
                      // eslint-disable-next-line no-alert
                      if (window.confirm('Are you sure you want to discard your edits?')) {
                        setPristine(true);
                        setEditView(!editView);
                      }
                    } else setEditView(!editView);
                  }}
                  outline
                  size="sm"
                >
                  <Icon name={editView ? 'x' : 'edit'} />
                </Button>
              </Tooltip>
              <Tooltip content="Preview…" singleton={bem('-navTooltip')}>
                <Button disabled={!!editView && !pristine} kind="secondary" outline size="sm" to={`${url}/preview`}>
                  <Icon name="eye" />
                </Button>
              </Tooltip>
              {commentsDropdown}
            </ButtonGroup>
          ) : (
            commentsDropdown
          )}
          <DateLine
            icon={update?.closed ? 'icon-x-circle' : update?.merged ? 'icon-git-merge' : 'icon-watch'}
            prefix={update?.closed ? 'Closed' : update?.merged ? 'Merged' : update?.updatedAt ? 'Updated' : 'Suggested'}
            suffix={
              !(update?.closed || update?.merged) && (
                <>
                  on&nbsp;
                  <Link to={`/docs/${pageSlug}`}>{update?.title_before}</Link>
                  {
                    /* eslint-disable-next-line no-irregular-whitespace */
                    effectiveUserName ? ` by ${effectiveUserName}` : ''
                  }
                </>
              )
            }
            tag="small"
            time={update?.closedAt || update?.mergedAt || update?.updatedAt || update?.createdAt}
          />
          {editView ? (
            <Tooltip content="Submit additional edits" delay={[1000, 0]}>
              <Button
                disabled={pristine || isSaving}
                kind="primary"
                loading={isSaving}
                onClick={saveUpdates}
                size="sm"
                style={{ marginLeft: 'auto' }}
                type="submit"
              >
                <Icon name="save" />
                Update
              </Button>
            </Tooltip>
          ) : (
            <ButtonGroup selectedKey={changesView} style={{ marginLeft: 'auto' }}>
              <Button key={'diff'} onClick={() => setChangesView('diff')} outline size="sm">
                Diff
              </Button>
              <Button key={'before'} onClick={() => setChangesView('before')} outline size="sm">
                Before
              </Button>
              <Button key={'after'} onClick={() => setChangesView('after')} outline size="sm">
                After
              </Button>
            </ButtonGroup>
          )}
        </nav>

        {editView ? (
          <div
            className={bem('-edit', 'markdown-body')}
            onKeyDown={e => {
              if (e.metaKey && e.key === 's') {
                e.preventDefault();
                if (!pristine) saveUpdates();
              }
            }}
            role="textbox"
            tabIndex={0}
          >
            <Prompt
              message={() => 'Are you sure you want to leave this page? Any unsaved changes will be lost.'}
              when={!pristine}
            />
            <MarkdownEditor
              doc={update?.body_after}
              domainFull={domainFull}
              glossaryTerms={terms}
              onChange={md => {
                setEditorValue(md);
                setPristine(false);
              }}
              projectBaseUrl={baseUrl}
              variableDefaults={vars.defaults}
            />
          </div>
        ) : (
          <div className={bem('-changes', `-changes_${changesView}`, 'markdown-body')}>
            <pre>
              <code dangerouslySetInnerHTML={{ __html: diffHtml }} />
            </pre>
          </div>
        )}
      </div>
      {!!reCaptchaSiteKey && (
        <Recaptcha ref={$recaptcha} badge="bottomleft" sitekey={reCaptchaSiteKey} size="invisible" />
      )}
    </>
  );
};

SuggestionDiff.propTypes = {
  diffHtml: PropTypes.string,
};

export default SuggestionDiff;

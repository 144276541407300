import emojiRegex from 'emoji-regex';
import Cookie from 'js-cookie';
import PropTypes from 'prop-types';
import React, { useContext, useRef, useState } from 'react';
import useSWR from 'swr';

import { BaseUrlContext, ProjectContext, UserContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';

import Badge from '@ui/Badge';
import Box from '@ui/Box';
import Button from '@ui/Button';
import Feed, { FeedEmptyState } from '@ui/Dash/Feed';
import DateLine from '@ui/DateLine';
import Icon from '@ui/Icon';
import SpeechBubble, { BubbleType } from '@ui/SpeechBubble';
import Tooltip from '@ui/Tooltip';

import classes from './style.module.scss';

const api = async (path, { body, headers = {}, ...options }) =>
  fetch(path, {
    mode: 'cors',
    credentials: 'include',

    ...options,

    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': Cookie.get('XSRF-TOKEN') ?? '',
      ...headers,
    },
  });

const SuggestionComments = ({ id, recaptcha: $recaptcha }) => {
  const $feed = useRef();
  const $textbox = useRef();

  const bem = useClassy(classes, 'SuggestionComments');

  const baseUrl = useContext(BaseUrlContext);
  const endpoint = `${baseUrl === '/' ? '' : baseUrl}/comments/update`;

  const { user, _id: userId, isAdmin = false } = useContext(UserContext);
  const { reCaptchaSiteKey } = useContext(ProjectContext).project;

  const { data, error, mutate } = useSWR(`${endpoint}/${id}`);
  const loading = !error && !data;
  const comments = data?.filter?.(c => !c.additionalEditsMade && !c.deleted);

  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const deleteComment = async commentId => {
    /**
     * @todo this logic should be wrapped in a window.confirm, but doing
     *       that would auto-closing the dropdown every time you deleted
     *       a comment! 😬
     */
    setDeleting(commentId);
    const xhr = await api(`${endpoint}/${commentId}`, { method: 'DELETE' });
    if (xhr.ok) mutate();
    setDeleting(false);
  };

  const comment = async body => {
    setSaving(true);

    let recaptchaResponse;
    if (reCaptchaSiteKey && $recaptcha) {
      $recaptcha.current?.reset();
      recaptchaResponse = await $recaptcha.current?.executeAsync();
    }

    const xhr = await api(endpoint, {
      method: 'POST',
      body: {
        body,
        user,
        parent: id,
        'g-recaptcha-response': recaptchaResponse,
      },
    });
    const res = await xhr.json();
    mutate([...data, res]);
    $textbox.current.value = '';
    setSaving(false);
    $feed.current.scrollTop = $feed.current.scrollHeight;
  };

  return (
    <Box className={bem('&', saving && '_saving')} kind="card" style={{ padding: 0 }}>
      <textarea
        ref={$textbox}
        aria-multiline="true"
        disabled={saving}
        onKeyDown={e => {
          if (e.shiftKey && e.key === 'Enter') {
            e.preventDefault();
            comment(e.target.value);
          }
        }}
        placeholder="Shift + Enter to comment…"
        rows={2}
      />
      <Feed ref={$feed} isLoading={loading} title="Comments">
        {comments?.length ? (
          comments.map(c => {
            const isOutgoing = c.hub2user._id === userId;
            const isDeleting = c._id === deleting;
            return (
              <div
                key={id}
                className={bem('-comment', isOutgoing && '-comment_outgoing', isDeleting && '-comment_deleting')}
              >
                <span className={bem('-comment-text')}>
                  <SpeechBubble
                    avatarProps={!isOutgoing && { ...c.effective_user, size: 'xs' }}
                    content={
                      emojiRegex().test(c.body) ? <span className={bem('-comment-emoji')}>{c.body}</span> : c.body
                    }
                    subtext={
                      !isOutgoing && (
                        <span>
                          {c.hub2user.user.name} ∕ <DateLine icon={false} prefix="" time={c.createdAt} />
                        </span>
                      )
                    }
                    type={BubbleType[isOutgoing ? 'OutgoingMessage' : 'IncomingMessage']}
                  />
                  {!isOutgoing && !!c.effective_user.isAdmin && (
                    <Badge ghost kind="info">
                      Admin
                    </Badge>
                  )}
                </span>
                {!!(isOutgoing || isAdmin) && (
                  <Tooltip content="Delete…" delay={[1250, 0]}>
                    <Button
                      className={bem('-comment-delete')}
                      ghost
                      kind="destructive"
                      onClick={() => deleteComment(c.id)}
                      size="xs"
                    >
                      <Icon name="x" />
                    </Button>
                  </Tooltip>
                )}
              </div>
            );
          })
        ) : (
          <FeedEmptyState description="Be the first!" heading="No one has said anything yet." icon="icon-callout" />
        )}
      </Feed>
    </Box>
  );
};

SuggestionComments.propTypes = {
  id: PropTypes.string.isRequired,
  recaptcha: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};

export default SuggestionComments;
